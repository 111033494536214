<footer class="footer no-print mb-2" style="width: 100%; height: 306px">
    <div class="container-fluid" style="height: 109px">
      <div class="d-flex align-items-center">
        <div class="w-100">
          <hr class="blue-hr" />
        </div>
        <div class="d-flex gap-0 column-gap-3 mx-2">
          <img
            class="mr-4 g-col-3"
            src="assets/icons/media_facebook.svg"
            alt="Icono SVG"
            />
            <img
            class="mr-4 g-col-3"
            src="assets/icons/media_twitter.svg"
            alt="Icono SVG"
            />
            <img
            class="mr-4 g-col-3"
            src="assets/icons/media_instagram.svg"
            alt="Icono SVG"
            />
            <img
            class="mr-4 g-col-3"
            src="assets/icons/media_linkedIn.svg"
            alt="Icono SVG"
          />
        </div>
        <div class="w-100">
          <hr class="blue-hr" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 d-none d-md-flex" style="display: flex; justify-content: center; align-items: center">
          <img style="width: 152.83px; height: 59px" src="assets/img/logo_sofofa.svg" alt="Icono SVG"
          />
        </div>
      </div>
    </div>
  
    <div class="row px-3 mt-4">
            <p style="margin-top: 25px;">
              Casa Matriz: Agustinas 1357. Pisos 11 y 12 - Santiago. Teléfono/Fax:
              (+562) 2336 28 00.<br /> <br>
              Agencias Regionales: Iquique - Antofagasta - Calama - Viña del Mar -
              Concepción - Temuco - Puerto Montt - Punta Arenas
            </p>
    </div>
  
    <div class="d-flex justify-content-center align-items-center" style="height: 66px; background-color: #184D9B;">
        <p class="text-light mt-2">
          2024 OTIC Sofofa | Capital Humano. Todos los derechos reservados
        </p>
    </div>
  </footer>
  