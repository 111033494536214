import { Component, OnDestroy, Renderer2, ViewChild, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwUpdate } from '@angular/service-worker';
//Componentes
import { MdlNotificacionesComponent } from 'modals/notificaciones/notificaciones.component';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
//Interfaces
import { Notificacion, MenuLink } from 'interfaces/Home.interface';
import { Usuario } from 'interfaces/Usuario.interface';

//Services
import { NotificationService } from 'services/notification.service';
import { AuthenticationService } from 'services/authentication.service';
import { WebServiceWorker } from 'services/web-service-worker.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userInfo: any;
  public toggle: boolean = false;
  $isAnyNewUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isNewAppVersionAvailable: boolean = false;
  newAppUpdateAvailableSubscription: any;
  versionHash: string | null = null;
  public routes: MenuLink[] = [
    {
      icon: 'bi-house-door',
      link: 'home',
      descripcion: 'Home',
      permisos: null,
      visible: true,
      cargo: [1,2,3,4,5,6,7,8,13]
    },
    {
      icon: 'bi-folder2-open',
      link:'solicitudes',
      descripcion: 'Solicitudes',
      permisos: null,
      visible: true,
      cargo: [1,2,3,4,5,6,7,8,13]
    },
    {
      icon: 'bi-building',
      link:'mantenedor-empresa',
      descripcion: 'Mantenedor de Empresas',
      permisos: null,
      visible: true,
      cargo: [1,2,3,4,5,6,7,8,13]
    },
    {
      icon: 'bi-people',
      link: 'mantenedor-usuario',
      descripcion: 'Mantenedor de Usuarios',
      permisos: null,
      visible: true,
      cargo: [1]
    }
  ];

  public notificaciones: Notificacion[] = [];
  public notificacionesNoLeidas: number = 0;

  constructor(private notificacionesService: NotificationService, private modalService: NgbModal, private authenticationService: AuthenticationService,private swUpdate: SwUpdate,private webServiceWorker: WebServiceWorker, ) {
    this.notificacionesService.message.subscribe((msg) => this.handleNotification(msg));
    this.notificacionesService.estado.subscribe(() => this.triggerOnKnow());
    this.authenticationService.currentUser.subscribe((user) => {
      if(user){
        this.userInfo = user;
        console.log(this.userInfo);

      }
    });
  }
  
  ngOnInit(): void {
    this.checkIfAppUpdated();
    if (this.swUpdate.isEnabled) {
      console.log('Service worker running.');
        this.swUpdate.versionUpdates.subscribe((event: any) => {
          console.log(event);
          this.versionHash = event?.version?.hash;
        });
    }
  }

  handleNotification(event: any){
    console.log('evento',event)
    if (event) {
      switch (event.tipo) {
        case 'onKnow':
          this.loadItems();
          break;
        case 'onMessage':
          console.log('actulizado para enviar notificaicon');
          const data = JSON.parse(event.data);
          console.log('data', data);
          break;
      }
    }
  }

  triggerOnKnow(){
    console.log('envio quieen soy');
    const data = {
      user: `${this.userInfo?.rut}-${this.userInfo?.dv}`,
      tipo: 'onKnow',
    };
    this.notificacionesService.message.next({ action:'onKnow', message: JSON.stringify(data) });
  }

  loadItems(){
    this.notificacionesService.getListadoNotificacion({ usuarioId: `${this.userInfo?.rut}-${this.userInfo?.dv}` })
      .subscribe((result) => {
        this.notificaciones = result.notificaciones;
        console.log('loadItems', result);
      });
      this.notificacionesService.getListadoNotificacionTotal(`${this.userInfo?.rut}-${this.userInfo?.dv}`).subscribe((result) => {
        this.notificacionesNoLeidas = result.total;
        console.log('loadItems', result);
    });
  }

  openNotificaciones(){
    const modalRef = this.modalService.open(MdlNotificacionesComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.notificaciones = this.notificaciones;

    // Marcar como leidas
    modalRef.result.then(() => {
      if(this.notificacionesNoLeidas > 0 ){
        const notificacionesLeidas = this.notificaciones.filter((notificacion) => !notificacion.leido).map(({ id }) => id);
        this.notificacionesService.setNotificacionLeida(notificacionesLeidas).subscribe()
      }
    });
  }

  logout(){
    this.authenticationService.logout();
  }

  checkIfAppUpdated() {
    this.newAppUpdateAvailableSubscription = this.webServiceWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
      this.isNewAppVersionAvailable = versionAvailableFlag;
    });
  }

  refreshApp() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.newAppUpdateAvailableSubscription?.unsubscribe();
  }
}
