<div class="modal-header border-left info">
    <h4 class="modal-title">Centro Notificaciones</h4>
    <a type="button" class="btn-close" aria-label="Close" (click)="modalRef.close({ estado: false})"></a>
</div>
<div class="modal-body">
    <h1 class="otic title">Mensaje</h1>
    @for (agrupador of grid | keyvalue:sort; track $index) {
        <div class="row mb-3 gap-1">
            <h3>{{ agrupador.key | titlecase }}</h3>
            @for (notificacion of agrupador.value; track $index) {
                <div class="col-12">
                    <div class="d-flex flex-row card shadow p-3 border-left" [ngClass]="notificacion.estado">
                        @switch (notificacion.estado) {
                            @case ('waiting') {
                                <img src="assets/icons/icon_success.svg" alt="">
                            }
                            @case ('success') {
                                <img src="assets/icons/icon_success.svg" alt="">
                            }
                            @case ('warning') {
                                <img src="assets/icons/icon_warning.svg" alt="">
                            }
                            @case ('error') {
                                <img src="assets/icons/icon_danger.svg" alt="">
                            }
                        }
                        <div class="d-grid grid text-start ms-3">
                            <div [innerHTML]="notificacion.mensaje | safeHTML"></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef.close({ estado : true})">Cerrar</button>
</div>