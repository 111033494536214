import { CommonModule, KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

//Utils
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

//Interfaces
import { Notificacion } from 'interfaces/Home.interface';

//Pipes
import { SafeHTMLPipe } from '../../../pipes/safe-html.pipe';

@Component({
  selector: 'app-notificaciones',
  standalone: true,
  imports: [CommonModule,NgbModalModule, SafeHTMLPipe],
  templateUrl: './notificaciones.component.html',
  styleUrl: './notificaciones.component.scss'
})
export class MdlNotificacionesComponent implements OnInit {
  @Input() notificaciones: Notificacion[] = [];
  public grid: Record<string,Notificacion[]> = {};
  
  constructor(public modalRef: NgbActiveModal){ }

  ngOnInit(): void {
    this.grid = this.notificaciones.reduce((acc:{[key:string]: Notificacion[]}, curr) => {
      const date = dayjs(curr.fecha).format('dddd DD / MMMM / YYYY');
      acc[date] = acc[date] || [];
      acc[date].push(curr);
      return acc;
    }, Object.create(null));
  }

  sort(a: KeyValue<string,Notificacion[]>, b: KeyValue<string,Notificacion[]>){
  return dayjs(a.key,'dddd DD / MMMM / YYYY').isAfter(dayjs(b.key,'dddd DD / MMMM / YYYY')) ? -1 : 1;
}
}
